@mixin bg-gradient {
    background: $primary;
    background: -webkit-linear-gradient(to left, $primary, #0d3bd1);
    background: linear-gradient(to left, $primary, #0d3bd1);
}



@mixin bg-custom-gradient {
    background: $primary;
    background: -webkit-linear-gradient(to right, $bg-mixin-custom-primary, $bg-mixin-custom-secondary);
    background: linear-gradient(to right, $bg-mixin-custom-primary, $bg-mixin-custom-secondary);
}