.subFooter {
  padding-bottom: 70px;
  margin-top: 30px;
  h1 {
    text-align: center;
    color: black;
    font-size: 1rem;
  }
  p {
    text-align: center;
    margin: 0;
    color: black;

  }
  
}

.linked {
  a {
    text-decoration: underline;
  }
}