/* Cart CSS */

.cart-table {
    position: relative;
    z-index: 1;

    table {
        max-width: 100%;

        tbody {
            tr {
                background-color: $white;
                border-bottom: 2px dashed $border;

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .cart-image {
            position: relative;
            margin-right: 0px;
            .button-checked-absolute {
                position: absolute;
                z-index: 1;
                top: 0px;
                left: 0px;
                .checkbox-cart {
                    padding: 0px;
                }
            }
            .button-remove-absolute {
                position: absolute;
                z-index: 1;
                top: 0px;
                right: 0px;
                height: 13px;
                width: 13px;
                color: white;
                background-color: red;
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                    margin-left: 2px;
                    transform: rotate(45deg) scale(1.5);
                }
            }
        }

        .product-title {
            color: black;
            font-size: 14px;
            line-height: 1.2em;
            height: 1.2em;
            -webkit-line-clamp: 1;

        }
        .product-price {
            display: flex;
            margin-top: 15px;
            .product-cost {
                margin-left: auto;
                padding-right: 10px;
                span {
                    line-height: 21px;
                }
            }

            .product-amount {
                display: flex;
                .text-orange {
                    width: 100px;
                    span {
                        line-height: 21px;
                    }
                }
            }
        }
        .product-attribute {
            font-size: 11px;
            padding-top: 10px;
            div {
                font-size: 13px;
                float: right;
                color: red;
                padding-right: 5px;
            }
        }
        a {
            color: $heading;
            font-weight: 700;

            &.remove-product {
                color: $white;
                width: 24px;
                height: 24px;
                background-color: $purple;
                display: inline-block;
                text-align: center;
                line-height: 25px;
                border-radius: 50%;
                font-size: 10px;

                &:hover,
                &:focus {
                    color: $white;
                    background-color: $heading;
                }
            }

            span {
                display: block;
                color: $text;
            }

            &:hover,
            &:focus {
                color: $primary;
            }
        }
    }

    .table td,
    .table th {
        // vertical-align:baseline;
        // vertical-align: middle;
        color: black;
        font-size: 12px;
        padding: 10px;
        border: 0;
    }
    .table td:nth-child(1) {
        width: 95px;
    }
    .table td:nth-child(2) {
        padding: 10px 0px;
    }

    img {
        width: 64px;
        height: 64px;
        object-fit: cover;
        border: 1px solid $border;
    }
}

.coupon-card {
    .card-body {
        padding: 1.25rem;
    }
}

.cart-amount-area {
    .card-body {
        padding: .75rem 1.25rem;
    }
}

.apply-coupon {
    position: relative;
    z-index: 1;

    p {
        font-size: 12px;
    }
}

.coupon-form {
    position: relative;
    z-index: 1;

    .form-control {
        height: 34.4px;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: .4rem;
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}