/* Preloader CSS */

#preloader {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    top: 0;
    left: 0;
    background-color: $bg-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}