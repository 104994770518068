/* Flash Sale CSS */

.flash-sale-wrapper {
  position: relative;
  z-index: 1;
  margin-bottom: 0.2rem;
  height: 265px;
  @media #{$breakpoint-sm} {
    height: 255px;
  }

  @media #{$breakpoint-md} {
      height: 265px;
  }

  @media #{$breakpoint-lg} {
      height: 290px;
  }

  @media #{$breakpoint-xl} {
      height: 290px;
  }
}

.flash-sale-slide.owl-carousel {
  width: 100% !important;


}

.slick-list > div {
  margin-left: 0;
}

.flash-sale-card {
  cursor: pointer;
  position: relative;
  z-index: 1;
  box-shadow: none !important;
  border-radius: 0px;
  border: 2px solid whitesmoke !important;


  .flash-sale-image {
    height: 115px;
    position: relative;

    @media #{$breakpoint-xxs} {
      height: 148px;
    }

    @media #{$breakpoint-sm} {
      height: 115px;
    }

    @media #{$breakpoint-md} {
        height: 129px;
    }

    @media #{$breakpoint-lg} {
        height: 165px;
    }

    @media #{$breakpoint-xl} {
        height: 158px;
    }


    .overlayProduct {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: auto;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      width: 60px;
      height: 60px;
      font-size: 12px;
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;
      text-align: center;
      // @media (max-width: 575px) {
      //   font-size: 10px;
      // }
    }

  }
  .flash-sale-info {
    height: 78px;
  }
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;

    // @media #{$breakpoint-lg} {
    //     height: 100% !important;
    // }

    // @media #{$breakpoint-xl} {
    //     width: 100% !important;
    // }
  }

  .product-title {
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 18px;
    height: 38px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    &:hover {
      color: $heading !important;
    }
    font-size: 16px;
  }

  .sale-price {
    height: 18px !important;
    font-size: 16px;

    color: $primary;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 3px !important;
  }

  .real-price {
    display: inline-block;
    margin-left: 5px;
    font-size: 12px;
    text-decoration: line-through;
    margin-bottom: 0.25rem;
    color: $text;
    height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .progress {
    height: 0.25rem;
  }

  .progress-title {
    color: $text;
    font-size: 12px;
    display: block;
  }

  &:hover,
  &:focus {
    .product-title {
      color: $primary;
    }
  }

}
