.MuiList-padding {
    padding: 0px !important;
}

.MuiDialogTitle-root {
    padding: 16px !important;
}

.react-share__ShareButton {
    width: 100% !important;
    margin-top: -16px !important;
}

.custom-social-message {
    margin-top: -16px;
    margin-bottom: 8px;
    cursor: pointer;
}