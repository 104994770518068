/* Setting Card CSS */

.settings-card {
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;

    .card-body {
        padding: 0.75rem 1rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.single-settings {
    position: relative;
    z-index: 1;

    .title {
        font-size: 14px;

        i {
            width: 22px;
            height: 22px;
            display: inline-block;
            background-color: #8A4821;
            text-align: center;
            color: $white;
            line-height: 22px;
            border-radius: .25rem;
            margin-right: .5rem;
            font-size: 14px;
        }

        span {
            span {
                font-size: 10px;
                margin-left: 0.5rem;
            }
        }
    }

    .data-content {
        font-size: 13px;
        font-weight: 700;

        a {
            display: flex;
            align-items: center;
        }

        i {
            margin-left: 0.25rem;
        }
    }

    .toggle-button-cover {
        position: relative;
        z-index: 1;
    }

    .knobs,
    .layer {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .button.r {
        position: relative;
        width: 45px;
        height: 21px;
        overflow: hidden;
        border: 1px solid $border;
    }

    .button.r,
    .button.r .layer {
        border-radius: 100px;
    }

    .checkbox {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;
    }

    .knobs {
        z-index: 2;
    }

    .layer {
        width: 100%;
        background-color: transparent;
        transition: 0.3s ease all;
        z-index: 1;
    }

    .knobs:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 2px;
        width: 15px;
        height: 15px;
        background-color: $purple;
        border-radius: 50%;
        transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
    }

    .checkbox:active+.knobs:before {
        width: 45px;
        border-radius: 100px;
    }

    .checkbox:checked:active+.knobs:before {
        margin-left: -26px;
    }

    .checkbox:checked+.knobs:before {
        content: '';
        left: 24px;
        background-color: $primary;
    }

    .checkbox:checked~.layer {
        background-color: $bg-gray;
    }
}