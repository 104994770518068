/* Profile Data CSS */

.single-profile-data {
    position: relative;
    z-index: 1;
    margin-bottom: 1.25rem;

    &:last-child {
        margin-bottom: 0;
    }

    .title {
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        font-size: 12px;
        color: $heading;

        i {
            width: 22px;
            height: 22px;
            display: inline-block;
            background-color: #8A4821;
            text-align: center;
            color: $white;
            line-height: 23px;
            border-radius: .25rem;
            margin-right: .4rem;
            font-size: 12px;
        }

        span {
            line-height: 1;
        }
    }

    .data-content {
        flex: 0 0 50%;
        width: 50%;
        max-width: 50%;
        font-size: 13px;
        text-align: right;
        color: $text;
    }
}

.user-data-card {
    position: relative;
    z-index: 1;
    border-color: $white;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .card-body {
        padding: 1.25rem;
    }

    .title {
        font-size: 13px;
        color: $heading;
        font-weight: 500;
        margin-bottom: 0;

        i {
            width: 24px;
            height: 24px;
            display: inline-block;
            background-color: $green;
            text-align: center;
            color: $white;
            line-height: 24px;
            border-radius: .25rem;
            margin-right: .4rem;
            font-size: 14px;
        }
    }

    .form-control {
        height: 36px;
        padding: 0 15px;
        color: $text;
        font-size: 13px;
    }

    textarea.form-control {
        height: auto;
        padding: 15px;
    }
}

.change-user-thumb {
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 30px;
    bottom: 0;
    right: 0;

    input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 10;
        opacity: 0;
    }

    button {
        width: 100%;
        height: 100%;
        background-color: $bg-gray;
        border: none;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        z-index: 1;
        border: 1px solid $border;
    }
}

.user-info-card {
    position: relative;
    z-index: 1;
    background-color: $yellow;
    border-color: $yellow;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    .user-profile {
        position: relative;
        z-index: 1;
        width: 80px;
        height: 80px;
        border-radius: 50%;

        img {
            border-radius: 50%;
        }
    }
}