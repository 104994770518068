/* Header CSS */

.header-area {
    transition-duration: 500ms;
    background-color: $white;
    width: 100%;
    height: 50px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0 8px rgba(15, 15, 15, 0.15);

    .cart-header {
        width: 20px;
        height: 20px;

        &.shake {
            animation: shakeCart .4s ease-in-out forwards;
        }

        position: relative;

        i {
            font-size: 20px;
            font-weight: 100;
            // transform: scaleX(-1);
        }

        .badge-header {
            position: absolute;
            border: 2px solid $custom-primary;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            top: -8px;
            right: -8px;
            border-radius: 50%;
            font-size: xx-small;
            background-color: $custom-primary;
            color: $white;
            z-index: 2;
        }
    }

}

.top-search-form {
    position: relative;
    z-index: 1;

    form,
    div.form {
        position: relative;
        z-index: 1;

        button i {
            color: black;
            font-weight: 350 !important;
            font-size: 15px;

        }

        .form-control {
            max-width: 190px;
            background-color: $white;
            height: 32px;
            font-size: 15px;
            padding: 10px 15px;
            padding-left: 20px;
            border: 1px solid $border;
            font-weight: 350;
            color: black;

            &::placeholder {
                color: black;
                opacity: 1;
                /* Firefox */
                font-size: 14px;
            }

            @media #{$breakpoint-xs-landscape} {
                max-width: 240px;
                width: 240px;
            }

            @media #{$breakpoint-md} {
                max-width: 280px;
                width: 280px;
            }

            @media #{$breakpoint-lg} {
                max-width: 280px;
                width: 280px;
            }

            @media #{$breakpoint-xl} {
                max-width: 350px;
                width: 350px;
            }
        }

        button {
            background-color: transparent;
            position: absolute;
            top: 1px;
            width: 18px;
            height: 30px;
            border: 0;
            z-index: 10;
            color: $text;

            &:focus {
                outline: none;
            }

        }
    }

    &.search-button-header {

        form,
        div.form {
            .form-control {
                padding-left: 30px;
            }

            button {
                left: 10px;
            }
        }
    }

    .search-suggestion {
        width: 100%;
        position: absolute !important;

        .title {

            h6,
            span {
                overflow: hidden;
                width: 100%;
                line-height: 0.6em;
                height: 0.6em;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                margin-bottom: unset !important;
            }
        }
    }
}

.suha-navbar-toggler {
    position: relative;
    z-index: 1;
    width: 26px;
    cursor: pointer;
    padding: .5rem 0;


    span {
        transition-duration: 500ms;
        flex: 0 0 22px;
        min-width: 22px;
        width: 22px;
        height: 2px;
        background-color: $text;
        margin-bottom: 4px;

        &:nth-child(2) {
            flex: 0 0 12px;
            min-width: 12px;
            width: 12px;
        }

        &:last-child {
            margin-bottom: 0;
            flex: 0 0 17px;
            min-width: 17px;
            width: 17px;
        }
    }

    &:hover,
    &:focus {
        span {
            flex: 0 0 22px;
            min-width: 22px;
            width: 22px;
        }
    }
}

.filter-option {
    position: relative;
    z-index: 1;
    cursor: pointer;
    text-align: right;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 20px;
}

.back-button {
    a {
        color: $text;
        font-weight: 500;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
            color: $primary;
        }
    }
}

.page-heading h6 {
    font-size: 14px;
}

.logo-wrapper a {
    display: block;
}