
.logo-wrapper {
  a, div {
    color: $custom-primary !important;
    font-weight: bolder;
    font-size:x-large;
    @media #{$breakpoint-xs} {
      font-size:large;
    }
    @media #{$breakpoint-xxs} {
      font-size: 14px !important;  
    }
  }
}
.anchor-disabled {
  pointer-events: none;
  cursor: default;
}
.footer-nav-area {
  box-shadow: 0 0 8px $custom-primary;
  ul > li {
    border: 0px !important;
    a, span {
      color: $custom-primary !important;
      cursor: pointer;
    }
    span {
      width: 100%;
    }
  }

}

.text-custom-primary {
  color: $custom-primary !important;
}
.btn.btn-custom-primary {
  background-color: $custom-primary;
  color: white ;
}

.loading-custom-primary {
  fill: $custom-primary !important;
}

.btn.btn-outline-custom-primary {
  border: 1px solid $custom-primary;
  font-weight: normal;
  margin: 5px;
}
.btn.btn-outline-custom-primary-active {
  border: 1px solid $custom-primary;
  color: $white !important;
  background-color: $custom-primary !important;
  font-weight: normal;
  margin: 5px;
}

.products-slider {
  &.pic-for-animation {
    width: 100%;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    top: 0px;

  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    img {
      display: block;
      height: 440px;
      width: 440px;
      object-fit: cover;
      @media #{$breakpoint-xxs} {
        width: 100vw;
        height: 100vw;
      }
     
    }
  }
}


$border: 0.5px solid whitesmoke;
$quantity-border-radius: 3px;
$quantity-color--primary: #f3f3f3;
$quantity-modifier--text-color: #888;


.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  // box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  border-radius: $quantity-border-radius;
  
  &:focus {
    background: red;
  }
  .modifier,
  .screen {
    outline: none;
  }
  .modifier {
    padding: 0.05rem;
    width: 1.5rem;
    font-size: 1rem;
    
    background: #fff !important;
    color: $custom-primary;
    border: $border;
    text-align: center;
    text-shadow: 0 1px 0 rgba(#fff, .6);
    
    cursor: pointer;
    
    &:hover {
      background: darken($quantity-color--primary, 10%);
      color: darken($quantity-modifier--text-color, 20%)
    }
    
    // &--left {
    //   border-radius: $quantity-border-radius 0 0 $quantity-border-radius;
    // }
    
    // &--right {
    //   border-radius: 0 $quantity-border-radius $quantity-border-radius 0;
    // }
    
  }
  .screen {
    width: 3rem;
    height: 27px;
    font-size: 0.9rem;
    border: 0;
    border-top: $border;
    border-bottom: $border;
    text-align: center;
    color: $custom-primary;
  }
  &.cart-xs {
    width: 64px;
    .modifier{
      padding: 0px;
      width: 1rem;
      font-size: 0.8rem;
    }
    .screen {
      width: 2rem;
      height: 21px;
      padding: .1rem;
      font-size: 0.7rem;
    }
  }
}

.footerDetail_customer {
  margin-top: 30px;
  div {
    a {
      color: black !important;
    }
    &.spacing {
      margin-right: 12.5%;
    }
  }
  
}

.text-right {
  text-align: right;
}