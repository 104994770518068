/* Live Chat CSS */

.live-chat-intro {
    position: relative;
    z-index: 1;
    background-color: $primary;
    padding: 2rem 0;
    text-align: center;
    border-radius: 0 0 3rem 3rem;

    p {
        color: $white;
        margin-bottom: 0.5rem;
    }

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin: 0 auto .5rem;
        display: block;
    }

    .status {
        position: relative;
        z-index: 1;
        color: $white;
        font-size: 14px;
        display: inline-block;

        &.online {
            padding-left: 15px;

            &::after {
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: $green;
                border-radius: 50%;
                content: "";
                margin-top: -5px;
                top: 50%;
                left: 0;
                z-index: 10;
                animation: flashing 1.5s infinite linear;
            }
        }
    }
}

.agent-message-content {
    position: relative;
    z-index: 1;
    margin-bottom: .5rem;

    .agent-thumbnail {
        flex: 0 0 30px;
        max-width: 30px;
        width: 30px;

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            box-shadow: 0 1px 3px 0 rgba(15, 15, 15, 0.175);
            background-color: $white;
            padding: 2px;
        }
    }

    .agent-message-text {
        position: relative;
        z-index: 1;

        p {
            display: inline-block;
            background-color: $white;
            padding: .625rem 1rem;
            border-radius: .75rem;
            margin-bottom: .375rem;
        }

        .writing-mode {
            display: inline-block;
            background-color: $white;
            padding: .625rem 1rem;
            border-radius: .75rem;
            margin-bottom: .375rem;

            .dot {
                position: relative;
                display: inline-block;
                width: 0.375rem;
                height: 0.375rem;
                border-radius: 50%;
                background-color: $border;
                animation: writingdot 1000ms linear 0s infinite;

                &:first-child {
                    margin-left: 0;
                }

                &:nth-child(2) {
                    animation-delay: 250ms
                }

                &:nth-child(3) {
                    animation-delay: 500ms;
                }
            }
        }

        span {
            display: block;
            font-size: 12px;
            color: $text;
            margin-left: 0.5rem;
            font-weight: 700;
            line-height: 1;
        }
    }
}

@keyframes writingdot {
    0% {
        top: 0;
    }

    50% {
        top: -6px;
    }

    100% {
        top: 0;
    }
}

.user-message-content {
    position: relative;
    z-index: 1;
    text-align: right;
    margin-bottom: .5rem;

    .user-message-text {
        padding-left: 3rem;

        p {
            display: inline-block;
            margin-bottom: .375rem;
            background-color: $primary;
            color: $white;
            padding: .625rem 1rem;
            border-radius: .75rem;
        }

        span {
            font-size: 12px;
            display: block;
            color: $text;
            margin-right: 0.5rem;
            font-weight: 700;
            line-height: 1;
        }
    }
}

.type-text-form {
    position: fixed;
    width: 100%;
    height: 55px;
    bottom: 0;
    left: 0;
    background-color: $white;
    z-index: 99999;

    .file-upload {
        position: absolute;
        width: 26px;
        height: 26px;
        top: 50%;
        left: .5rem;
        z-index: 20;
        border: 2px solid $border;
        border-radius: 50%;
        transform: translateY(-50%);
        cursor: pointer;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 200;
            opacity: 0;
            cursor: pointer;
            border-radius: 50%;
        }

        i {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $white;
            height: 100%;
            width: 100%;
            z-index: 50;
            text-align: center;
            border-radius: 50%;
            font-size: 12px;
            line-height: 21px;
        }
    }

    .form-control {
        transition-duration: 500ms;
        width: 100%;
        height: 55px;
        border-color: transparent;
        color: $text;
        font-size: 13px;
        padding-top: 17px;
        padding-bottom: 17px;
        padding-left: 2.5rem;

        &:focus {
            border-color: transparent;
        }
    }

    button[type="submit"] {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 50%;
        right: .5rem;
        z-index: 30;
        border: 0;
        background-color: $white;
        color: $heading;
        outline: none !important;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}