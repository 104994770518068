/* Featured Product Card CSS */

.featured-product-card {
    position: relative;
    z-index: 1;

    .custom-badge {
        position: absolute;
        left: 1rem;
        top: 1px;
        z-index: 100;
        height: 22px;
        border-radius: 0;
        padding: 0 0 0 .75rem;
        line-height: 22px;
        transform: rotate(90deg);

        &::before {
            border-color: $yellow transparent $yellow $yellow;
            border-style: solid;
            border-width: 11px 8px;
            content: "";
            height: 22px;
            position: absolute;
            right: -17px;
            top: 0;
            width: 17px;
            z-index: 1;
        }
    }

    .product-thumbnail-side {
        position: relative;
        z-index: 1;
        margin-bottom: 0.5rem;

        .product-thumbnail {
            position: relative;
            z-index: 1;
            text-align: center;

            img {
                max-height: 150px;
            }
        }

        .wishlist-btn {
            position: absolute;
            top: 0rem;
            right: 0rem;
            color: $purple;
            line-height: 1;
            z-index: 11;
            font-size: 1.25rem;

            &:hover,
            &:focus {
                transform: translateY(-2px);
            }
        }
    }

    .product-description {
        position: relative;
        z-index: 1;

        .product-title {
            margin-bottom: 0.25rem;
            font-weight: 700;
            font-size: 14px;
            color: $heading;

            &:hover,
            &:focus {
                color: $primary;
            }
        }

        .sale-price {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 700;
            color: $primary;
            line-height: 1;
        }
    }
}