/* Catagory Card CSS */

.catagory-card {
    position: relative;
    z-index: 1;
    text-align: center;
    transition-duration: 500ms;

    a {
        display: block;
    }

    i {
        margin-bottom: 0.75rem;
        font-size: 1.75rem;
        display: block;
        color: $primary;
    }

    span {
        transition-duration: 500ms;
        display: block;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        color: $text;
    }

    &:hover,
    &:focus {
        background-color: $bg-gray;

        span {
            color: $primary;
        }
    }
}

.catagory-single-img {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 160px;
    background-position: center center;
    background-size: cover;
    border-radius: .5rem;

    @media #{$breakpoint-sm} {
        height: 190px;
    }

    @media #{$breakpoint-md} {
        height: 220px;
    }

    @media #{$breakpoint-lg} {
        height: 250px;
    }

    @media #{$breakpoint-xl} {
        height: 290px;
    }
}