/* Notification CSS */

.notification-area {
    position: relative;
    z-index: 1;

    .list-group-item {
        border-color: $border;
        margin-bottom: 0.5rem;
        border-radius: 0.5rem;

        .noti-icon {
            position: relative;
            z-index: 1;
            display: inline-block;
            width: 30px;
            height: 30px;
            flex: 0 0 30px;
            max-width: 30px;
            @include bg-gradient;
            color: $white;
            text-align: center;
            font-size: 1rem;
            margin-right: .75rem;
            border-radius: 50%;

            i {
                line-height: 30px;
            }

            &::before {
                position: absolute;
                width: 10px;
                height: 10px;
                background-color: $yellow;
                content: "";
                top: -2px;
                right: -2px;
                border-radius: 50%;
                z-index: 1;
            }
        }

        h6 {
            font-size: 14px;
            transition-duration: 500ms;
            color: $heading;
        }

        p {
            margin-bottom: 0.5rem;
        }

        a {
            font-size: 14px;
        }

        span {
            font-size: 12px;
            display: block;
            color: $text;
        }

        &:first-child {
            border-top-left-radius: .5rem;
            border-top-right-radius: .5rem;
        }

        &:last-child {
            border-bottom-right-radius: .5rem;
            border-bottom-left-radius: .5rem;
        }

        &.readed {
            background-color: $bg-gray;

            .noti-icon {
                background: $green;

                &::before {
                    display: none;
                }
            }

            h6 {
                color: $text;
            }
        }

        &:hover,
        &:focus {
            h6 {
                color: $primary;
            }
        }
    }

    .unread-notifications {
        position: relative;
        z-index: 1;
    }
}