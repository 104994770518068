/* Footer CSS */

.footer-nav-area {
    transition-duration: 500ms;
    background-color: $white;
    width: 100%;
    height: 58px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0 0 8px rgba(15, 15, 15, 0.15);
}

.suha-footer-nav {
    ul {
        width: 100%;

        li {
            flex: 1 1 0;
            position: relative;
            z-index: 1;

            a, span {
                position: relative;
                display: block;
                font-size: 10px;
                text-align: center;
                text-transform: uppercase;
                line-height: 1;
                color: $text;
                z-index: 1;

                &.text-gray {
                    color: $text-gray !important;
                    cursor: no-drop;
                }

                i {
                    display: block;
                    font-size: 20px;
                    margin-bottom: 4px;
                }

                &:hover,
                &:focus {
                    color: $primary;
                }

                img {
                    max-height: 1.5rem;
                    margin: 0 auto .25rem;
                    display: block;
                }
            }

            &.active {
                a {
                    color: $primary;
                }
            }
        }
    }
}