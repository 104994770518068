/* Blog CSS */

.post-bookmark-wrap {
    position: relative;
    z-index: 1;
}

.blog-card {
    position: relative;
    z-index: 1;
    border: 0;

    .post-img {
        position: relative;
        z-index: 1;
        border-radius: 0.5rem;

        img {
            border-radius: 0.5rem;
        }

        &::after {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            content: "";
            background-color: $heading;
            opacity: 0.3;
            z-index: 1;
            border-radius: 0.5rem;
        }
    }

    .post-bookmark {
        position: absolute;
        z-index: 100;
        color: $white;
        top: .5rem;
        right: .5rem;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: transparent;
        text-align: center;

        i {
            line-height: 30px;
        }

        &:hover,
        &:focus {
            background-color: rgba(255, 255, 255, 0.2);
        }
    }

    .post-content {
        position: absolute !important;
        width: calc(100% - 1rem);
        bottom: .5rem;
        left: .5rem;
        z-index: 100;
        border-radius: .5rem;
        background-color: $white;
        padding: .75rem;
        overflow: hidden;

        .bg-shapes {

            .circle1 {
                width: 30px;
                height: 30px;
                position: absolute;
                border-radius: 50%;
                top: -15px;
                right: -15px;
                background-color: transparent;
                border: 1px solid $border;
                z-index: -1;
            }

            .circle2 {
                width: 50px;
                height: 50px;
                position: absolute;
                border-radius: 50%;
                top: -25px;
                right: -25px;
                background-color: transparent;
                border: 1px solid $border;
                z-index: -1;
            }

            .circle3 {
                width: 70px;
                height: 70px;
                position: absolute;
                border-radius: 50%;
                top: -35px;
                right: -35px;
                background-color: transparent;
                border: 1px solid $border;
                z-index: -1;
            }

            .circle4 {
                width: 90px;
                height: 90px;
                position: absolute;
                border-radius: 50%;
                top: -45px;
                right: -45px;
                background-color: transparent;
                border: 1px solid $border;
                z-index: -1;
            }
        }

        .post-catagory {
            font-size: 11px;
            text-transform: uppercase;
            font-weight: 700;
            color: $purple;
            margin-bottom: 0.125rem;
            letter-spacing: .5px;
        }

        .post-title {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.3;
            color: $heading;
            margin-bottom: .5rem;
            overflow: hidden;
            height: 38px;

            &:hover,
            &:focus {
                color: $primary;
            }
        }

        .post-meta {
            position: relative;
            z-index: 1;

            a {
                font-size: 10px;
                line-height: 1;
                color: $text;

                &:hover,
                &:focus {
                    color: $primary;
                }

                i {
                    margin-right: 0.25rem;
                }
            }

            span {
                font-size: 10px;
                line-height: 1;
                color: $text;

                i {
                    margin-right: 0.25rem;
                }
            }
        }
    }

    &.list-card {
        .post-content {
            width: calc(50% - 1rem);
            bottom: .75rem;
            left: .75rem;
        }

        .post-bookmark {
            top: .75rem;
            right: .75rem;
        }

        .read-more-btn {
            position: absolute;
            min-width: 100px;
            right: .75rem;
            bottom: .75rem;
            z-index: 99;
        }
    }
}

.blog-catagory-card {
    position: relative;
    z-index: 1;

    a {
        display: block;

        i {
            color: $purple;
            font-size: 2rem;
            margin-bottom: 1rem;
            display: block;
        }

        span {
            transition-duration: 500ms;
            color: $text;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            letter-spacing: .5px;
        }

        &:hover,
        &:focus {
            span {
                color: $primary;
            }
        }
    }
}

.blog-details-post-thumbnail {
    position: relative;
    z-index: 1;
    margin-bottom: -40px;

    .post-bookmark {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: $purple;
        text-align: center;
        position: absolute;
        top: 1rem;
        left: 0;
        z-index: 100;
        color: $white;

        i {
            line-height: 35px;
        }

        &:hover,
        &:focus {
            background-color: $primary;
            color: $white;
        }
    }
}

.product-title-meta-data {
    position: relative;
    z-index: 1;

    .post-catagory {
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: .5px;
        color: $purple;
        font-weight: 700;
    }
}

.post-meta-data {
    a {
        color: $text;
        font-size: 12px;

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 0.25rem;
        }

        span {
            transition-duration: 500ms;
            padding: 3px 8px;
            border: 1px solid $primary;
            border-radius: .25rem;
            margin-left: 0.5rem;
            color: $primary;
        }

        &:hover,
        &:focus {
            span {
                border-color: $primary;
                color: $white;
                background-color: $primary;
            }
        }
    }

    span {
        color: $text;
        font-size: 12px;

        i {
            margin-right: 0.25rem;
        }
    }
}

.post-content {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}