/* Contact CSS */

.form-control {
    transition-duration: 500ms;
    border-color: $border;
    height: 50px;
    padding: 0.375rem 1rem;
    font-size: 14px;

    &:focus {
        box-shadow: none;
    }
}

.contact-wrap {
    position: relative;
    width: 100%;
    min-height: 100vh;
    z-index: 10;
    overflow-y: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow-x: hidden;
    background-color: #e7eaf5;
}

.contact-form {
    position: relative;
    z-index: 1;

    .form-group {
        position: relative;
        z-index: 1;
    }

    form>.form-group>label {
        position: absolute;
        text-align: center;
        z-index: 100;
        border-radius: 50%;
        left: 10px;
        font-size: 16px;
        margin-bottom: 0;
        color: $text;
        top: 11px;
    }

    .form-control {
        border-radius: 0.5rem;
        border: 0;
        color: $text;
        font-size: 13px;
        background-color: $white;
        height: 46px;
        padding: 0.75rem 1.25rem;

        &::placeholder {
            color: $text;
            opacity: 1;
        }
    }

    textarea.form-control {
        height: 120px;
    }
}

.google-maps-wrap {
    position: relative;
    z-index: 1;

    iframe {
        width: 100%;
        height: 300px;
        border: 0;
    }
}