.print-cart p {
  color: black !important;
}

.print-cart .table-list-product {
  font-size: 11px;
  width: 100%;
  color: black;
}

.print-cart .table-list-product, .print-cart .table-list-product th, .print-cart .table-list-product td, .print-cart .table-list-product tr {
  border: 1px solid black;
  padding: 2px;
}
.print-cart .table-list-product tr:nth-child(1) td {
  text-align: center;
  font-weight: bold;
}
.print-cart .table-list-product td:nth-child(1), .print-cart .table-list-product td:nth-child(4) {
  width: 5%;
  text-align: center;
}
.print-cart .table-list-product td:nth-child(2) {
  width: 42%;
}
.print-cart .table-list-product td:nth-child(3) {
  width: 18%;
}
.print-cart .table-list-product td:nth-child(5), .print-cart .table-list-product td:nth-child(6) {
  width: 15%;
  text-align: right;
}
@page {
  margin: 20px;
}
