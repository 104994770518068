@import 'bootstrap/dist/css/bootstrap.min.css';
@import './fontawesome/css/font-awesome.min.css';
@import 'swiper/swiper.min.css';
@import 'swiper/components/pagination/pagination.min.css';
@import 'swiper/components/navigation/navigation.min.css';
@import "slick-carousel/slick/slick.css"; 
@import 'animate.css';
@import './print-cart.css';

/* @font-face {
  font-family: "Segoe UI";
  src: url("../public/fonts/Segoe_UI.woff");
} */
html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  
}

a {
  color: inherit;
  text-decoration: none;
}
.bg-orange{
  background-color: #d63f08;
  color: white;
}
.text-orange{
  color: #d63f08;
}
.text-gray {
  color: #747794;
}
.p-errors {
  color: red !important;
  text-align: end;
  margin-bottom: 0;
}
.font-weight-400 {
  font-weight: 400;
}
.flex-1 {
  flex: 1;
}

* {
  box-sizing: border-box;
}

li {
  border-bottom: 1px solid #e9ecef;
}
li:last-child {
  border-bottom: none;
}
.logo-wrapper {
  cursor: pointer;
}
/* .sidebar-child li:last-child{
  border-bottom: 1px solid #e9ecef;
} */
.sidebar-child {
  padding-left: 0.7rem;
}
.category-slider .sidenav-nav li h1 {
  font-size: 12px;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.btn-back {
  font-size: 26px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.suha-sidenav-wrapper a.btn-back i {
  color: white !important;
}
.sidenav-profile  {
  display: flex;
}
.category-slider .sidenav-profile .user-info{
  padding: 1rem;
}
.category-slider .sidenav-nav li h1 {
  font-weight: 600;
}
/* Search suggestion start */
.search-suggestion strong{
  font-weight: 350;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1em;
  height: 1em;
  color: black;
  font-size: smaller;
}

.search-suggestion img{
  height: 40px !important;
  width: 40px !important;
  max-width: unset;
}

.search-suggestion  .search-info {
  width: calc(100% - 1rem - 40px) !important;
}

.search-suggestion .category {
  color: #d63f08 !important;
}
.info-category, .info-category .category {
  font-size: 12px;
}
/* Search suggestion end */

/* Login Start*/
.login {
  background-color: #fff;
  color: black;
}

.login input::placeholder {
  color: black;
  font-size: 15;
}
.register .btn-login {
  margin: auto;
  flex: 1;
  text-align: left;
}

.login .btn-login {
  margin: auto;
  flex: 1;
  text-align: left;
}

.login a {
  align-items: center;
}
.login .passord-wrap {
  position: relative;
}
.login .form-control {
  height: 35px;
}
.login .eye {
  right: 10px;
}
/* Login End*/

/* Register Start */
.register {
  color: black;
}

.register .address-transport .address{
  color: black !important;

}

.address-transport textarea.address{
  min-height: 80px !important;
}

.register ::placeholder {
  color: black;
  font-size: 15;
}

.register .gender-label {
  flex: 1;
}


.register .female{
  padding-right: 15px;
}
.register .passord-wrap {
  width: 65%;
  position: relative;
}
.register .password-field {
  margin: auto;
  width: 100%;
  padding-right: 30px;
}
.eye {
  position: absolute;
  right: 0;
}
.eye:hover, .eye.active {
  color: #d63f08;
}
/* Register End */

/* Cart page  start*/
.cart {
  font-size: 14px;
  color: black !important;
}

.cart p {
  color: black !important;
}

.cart p.p-errors {
  color: red !important;
}

.voucher-form form{
  background-color: #fff;
  display: flex;
  padding: 20px 10px;
}
.voucher-form input{
  padding: 10px;
  width: 75%;
  height: 35px;
}
button.btn-apply{
  font-weight: 400;
  margin-left: auto;
}
.transport {
  margin-top: 30px;
  background-color: #fff;
  padding: 10px;
}

.transport-switch {
  min-height: 40px;
}
.transport ul {
  padding: 0;
}
.transport ul li {
  padding: 10px;
}
.transport .switch-parent {
  float: right;
  margin-left: 10px;
  
}
.transport .switch-parent span {
  margin-right: 10px;
}

.clear-both {
  clear: both;
}
.address {
  border: none;
  border-bottom: 1px solid #ebebeb;
  height: 35px !important;
  margin-bottom: 10px;
  text-align: right;
  width: 70%;
  color: #747794;
}

input.address {
  color: #000;
}

select.address {
  color: #000;
  text-align-last: end;
}

select.address:invalid {
  color: #747794 !important;
}


.address-label{
  width: 30%;
}
.address-transport{
  display: flex;
  align-items: center;
}
.form-transport.active{
  display: block;
}
.form-transport {
  display: none;
}

/* Cart page  end*/

/* History page start */
.order .data{
  padding: 5px 0;
}
.history li{
  padding: 5px 0;
}
.history .content{
  padding-left: 10px
}
.history .content,
.history .product-type,
.history .total-qty,
.history .name-shop,
.history .btn-cancel,
.btn-refund {
  margin: auto;
  flex: 1;
}
.history .btn-cancel {
  display: inherit;
}
.history .name-shop {
  font-weight: 600;
  font-size: 16px;
}
.btn-cancel .btn:hover , .btn-cancel .btn:focus {
  color: #d63f08;
}
.history .icon {
  margin-right: 10px;
}
.history .info-transport li,
.history .info-user li {
  border-bottom: unset;
  padding: 0;
}
.history .info-transport li:nth-child(2){
  list-style: circle;
}
.border-botto-solid, .cart .cart-table table tbody tr {
  border-bottom: 1px solid #e9ecef;
}
/* History page end */
/* Search filter Start */
.search-filter-slider .sidenav-profile {
  background: #f8f9fa;
  color: #000;
}
.search-filter-slider a.btn-back i {
  color: #d63f08 !important;
}
.search-filter-slider.suha-sidenav-wrapper.nav-active {
  width: 90%;
}
.bg-btn-filter {
  background: #f4f4f4;
}
.border-orange {
  border: 1px solid #d63f08;
}
.btn.border-orange:hover, .btn.border-orange:focus {
  color: #747794;
}
.category.row .col-6 {
  font-size: 0.85em;
}
.category.row .col-6 .bg-btn-filter{
  min-height: 42px;
}
.btn-more {
  border-bottom: 1px solid #dee2e6;
}
.item-category.active {
  border: 1px solid #d63f08;
}
/* Search filter End */
/* Profile Start */
input.form-control {
  height: 35px;
}
.btn-filter {
  position: absolute;
  top: 20px;
  right: 12px;
}
.top-products-area .section-heading {
  width: 90%;
}
.history .product-title,
.cart-table .product-title,
.order-detail .product-title{
  transition-duration: 500ms;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.history img {
  border-radius: 10px;
}
/* Profile End */
input.search-bill {
  border: unset !important;
}

.history .btn-reorder {
  margin-left: 5px;
}

.display-linebreak {
  white-space: pre-line;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer-disabled {
  cursor: no-drop;
}

.ml-2 {
  margin-left: 10px;
}

input:focus::placeholder {
  color: transparent;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */