/* Animation CSS */

@-webkit-keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(359deg)
    }
}

@keyframes spin {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(359deg)
    }
}

@-webkit-keyframes burst {
    0% {
        transform: scale(1);
        opacity: 1
    }

    90% {
        transform: scale(1.5);
        opacity: 0
    }
}

@keyframes burst {
    0% {
        transform: scale(1);
        opacity: 1
    }

    90% {
        transform: scale(1.5);
        opacity: 0
    }
}

@-webkit-keyframes flashing {

    0%,
    90% {
        opacity: 1
    }

    45% {
        opacity: 0
    }
}

@keyframes flashing {

    0%,
    90% {
        opacity: 1
    }

    45% {
        opacity: 0
    }
}

@-webkit-keyframes fade-left {
    0% {
        transform: translateX(0);
        opacity: 1
    }

    75% {
        transform: translateX(-20px);
        opacity: 0
    }
}

@keyframes fade-left {
    0% {
        transform: translateX(0);
        opacity: 1
    }

    75% {
        transform: translateX(-20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-right {
    0% {
        transform: translateX(0);
        opacity: 1
    }

    75% {
        transform: translateX(20px);
        opacity: 0
    }
}

@keyframes fade-right {
    0% {
        transform: translateX(0);
        opacity: 1
    }

    75% {
        transform: translateX(20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-up {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    75% {
        transform: translateY(-20px);
        opacity: 0
    }
}

@keyframes fade-up {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    75% {
        transform: translateY(-20px);
        opacity: 0
    }
}

@-webkit-keyframes fade-down {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    75% {
        transform: translateY(20px);
        opacity: 0
    }
}

@keyframes fade-down {
    0% {
        transform: translateY(0);
        opacity: 1
    }

    75% {
        transform: translateY(20px);
        opacity: 0
    }
}

@-webkit-keyframes tada {

    from,
    to {
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }

    40%,
    60%,
    80% {
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, -10deg)
    }
}

@keyframes tada {

    from,
    to {
        transform: scale3d(1, 1, 1)
    }

    10%,
    20% {
        transform: scale3d(.95, .95, .95) rotate3d(0, 0, 1, -10deg)
    }

    30%,
    50%,
    70%,
    90% {
        transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg)
    }

    40%,
    60%,
    80% {
        transform: rotate3d(0, 0, 1, -10deg)
    }
}

.lni-spin-effect,
.lni-spin-effect-hover:hover {
    animation: spin 2s linear infinite
}

.lni-tada-effect,
.lni-tada-effect-hover:hover {
    animation: tada 1.5s ease infinite
}

.lni-flashing-effect,
.lni-flashing-effect-hover:hover {
    animation: flashing 1.5s infinite linear
}

.lni-burst-effect,
.lni-burst-effect-hover:hover {
    animation: burst 1.5s infinite linear
}

.lni-fade-up-effect,
.lni-fade-up-effect-hover:hover {
    animation: fade-up 1.5s infinite linear
}

.lni-fade-down-effect,
.lni-fade-down-effect-hover:hover {
    animation: fade-down 1.5s infinite linear
}

.lni-fade-left-effect,
.lni-fade-left-effect-hover:hover {
    animation: fade-left 1.5s infinite linear
}

.lni-fade-right-effect,
.lni-fade-right-effect-hover:hover {
    animation: fade-right 1.5s infinite linear
}