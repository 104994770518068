/* Cta Area */

.cta-area {
    position: relative;
    z-index: 2;

    .cta-text {
        position: relative;
        z-index: 1;
        border-radius: 0.5rem;
        background-size: cover;
        background-position: center top;

        p,
        h4 {
            color: $white;
        }

        &::after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            content: "";
            @include bg-gradient;
            z-index: -10;
            border-radius: 0.5rem;
            opacity: .75;
        }
    }
}