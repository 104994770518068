/* Welcome Page CSS */

.intro-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    @include bg-gradient;
    z-index: 1;

    .background-shape {
        position: absolute;
        width: 320px;
        height: 320px;
        border-radius: 50%;
        background-color: $white;
        opacity: 0.15;
        z-index: -10;
        top: -130px;
        right: -85px;
    }
}

.get-started-btn {
    position: fixed;
    z-index: 100;
    bottom: 40px;
    width: 280px;
    left: 50%;
    margin-left: -140px;
}

/* Hero Slide CSS */

.hero-slides {
    position: relative;
    z-index: 1;

    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        background-color: rgba(255, 255, 255, 0.11);
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        left: 0;
        margin-top: -15px;
        color: $white;
        display: none;
    }

    .owl-next {
        left: auto;
        right: 0;
    }

    .owl-dots {
        position: absolute;
        width: 100%;
        bottom: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        .owl-dot {
            transition-duration: 500ms;
            position: relative;
            z-index: 1;
            margin: 0 5px;
            width: 8px;
            height: 8px;
            flex: 0 0 8px;
            max-width: 8px;
            background-color: $border;
            border-radius: 50%;

            &.active {
                background-color: $yellow;

                &::after {
                    position: absolute;
                    width: calc(100% + 8px);
                    height: calc(100% + 8px);
                    z-index: 1;
                    content: "";
                    border: 2px solid $yellow;
                    left: -4px;
                    top: -4px;
                    border-radius: 50%;
                }
            }
        }
    }
}
.slider-wapper {
    min-height: 200px;

    // @media #{$breakpoint-xxs} {
    //     margin-bottom: -100px;
    // }

    // @media #{$breakpoint-xs-landscape} {
    //     height: 200px;
    // }

    @media #{$breakpoint-sm} {
        min-height: 240px;
    }

    @media #{$breakpoint-md} {
        min-height: 280px;
    }

    @media #{$breakpoint-lg} {
        min-height: 320px;
    }

    @media #{$breakpoint-xl} {
        min-height: 420px;
    }
}
.single-hero-slide {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 180px;
    background-position: center center;
    background-size: cover;
    // border-radius: .5rem;

    @media #{$breakpoint-xs-landscape} {
        height: 200px;
    }

    @media #{$breakpoint-sm} {
        height: 220px;
    }

    @media #{$breakpoint-md} {
        height: 280px;
    }

    @media #{$breakpoint-lg} {
        height: 320px;
    }

    @media #{$breakpoint-xl} {
        height: 440px;
    }

    .slide-content {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;

        .slide-text {
            padding-left: 2rem;
            padding-right: 1rem;
        }

        h4 {
            @media #{$breakpoint-md} {
                font-size: 2rem;
            }

            @media #{$breakpoint-lg} {
                font-size: 2rem;
            }

            @media #{$breakpoint-xl} {
                font-size: 2.5rem;
            }
        }

        p {
            @media #{$breakpoint-md} {
                font-size: 1.125rem;
            }

            @media #{$breakpoint-lg} {
                font-size: 1.125rem;
            }

            @media #{$breakpoint-xl} {
                font-size: 1.25rem;
            }
        }
    }
}