.footerDetail {
  display: flex;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: block;
  }

  &_wrapImage {
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  &_image {
    width: 60px;
    margin: auto;
  }

  &_about {
    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }
  h6 {
    color: #888888;
    @media (max-width: 767px) {
      text-align: center;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      font-size: 14px;
      margin-top: 10px;
      cursor: pointer;
      @media (max-width: 767px) {
        text-align: center;
        &:nth-child(3) {
          border-bottom: 1px solid #e9ecef;
        }
        &.hiddenBorder {
          border-bottom: unset  !important;
        }
      }
      a {
        color: black;
        &:hover {
          color: black;
        }
      }
    }
  }
}
