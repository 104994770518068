/* Product Details CSS */

.product-slides {
    position: relative;
    z-index: 1;
    margin-bottom: -40px;

    .owl-prev,
    .owl-next {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: transparent;
        text-align: center;
        line-height: 30px;
        z-index: 10;
        top: 50%;
        margin-top: -15px;
        left: 15px;
        font-size: 20px;
        color: $primary;

        &.disabled {
            opacity: 0;
            visibility: hidden;
        }
    }

    .owl-next {
        left: auto;
        right: 15px;
    }

    .owl-dots {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 1rem;
        z-index: 10;

        .owl-dot {
            transition-duration: 300ms;
            flex: 0 0 12px;
            width: 12px;
            max-width: 12px;
            height: 2px;
            background-color: $border;
            margin: 0 5px;

            &.active {
                flex: 0 0 24px;
                width: 24px;
                max-width: 24px;
                background-color: $yellow;
            }
        }
    }
}

.product-title-meta-data {
    padding: 10px;
}

.product-description {
    position: relative;
    z-index: 10000;

    &-name{
        font-size: 16px;
    }

    .sale-price {
        font-size: 18px;
        font-weight: 700;
        color: $purple;
        margin: 0px;
    }

    .ratings {
        i {
            color: $yellow;
            font-size: 12px;
            margin-right: 3px;
        }

        span {
            font-size: 12px;
            color: $text;
        }
    }

    .total-result-of-ratings {
        span:first-child {
            background-color: $green;
            padding: 3px 6px;
            color: $white;
            display: inline-block;
            border-radius: .25rem;
            font-size: 12px;
            margin-right: 0.25rem;
            font-weight: 700;
        }

        span:last-child {
            color: $green;
            font-size: 12px;
            font-weight: 700;
        }
    }
}

.cart-form {
    position: relative;
    z-index: 1;
    display: flex;
    .order-plus-minus {
        width: 150px;
    }
    .form-control {
        max-width: 50px;
        height: 35px;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        text-align: center;
        font-weight: 700;
        padding: 0.375rem 0.5rem;
    }

    .quantity-button-handler {
        width: 35px;
        height: 35px;
        background-color: $bg-gray;
        border: 1px solid $border;
        color: $heading;
        line-height: 33px;
        font-size: 1.25rem;
        text-align: center;
        border-radius: 0.25rem;
        cursor: pointer;
        transition-duration: 500ms;

        &:hover {
            color: $primary;
        }
    }
}

.p-wishlist-share {
    a {
        display: inline-block;
        color: $purple;
        font-size: 1.2em;
    }
}

.choose-color-radio {
    .form-check-input {
        border: 0;
        background-color: $bg-gray;

        &.blue {
            background-color: $primary;
        }

        &.yellow {
            background-color: $yellow;
        }

        &.green {
            background-color: $green;
        }

        &.purple {
            background-color: $purple;
        }

        &:checked {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }
    }
}

.choose-size-radio {
    position: relative;
    z-index: 1;
    text-align: right;

    .form-check-input {
        background-color: $bg-gray;
        border-radius: 0.25rem;

        &:checked {
            background-color: $purple;
            border-color: $purple;
            background-image: none;
        }
    }

    .form-check-label {
        font-size: 12px;
        font-weight: 700;
    }
}

.p-specification {
    position: relative;
    z-index: 1;

    li {
        font-size: 14px;
    }
}

.single-user-review {
    position: relative;
    z-index: 1;
    border-bottom: 2px dashed $border;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    .user-thumbnail {
        margin-top: 0.5rem;
        flex: 0 0 40px;
        width: 40px;
        max-width: 40px;
        margin-right: .5rem;

        img {
            border-radius: 50%;
        }
    }

    .rating {
        font-size: 12px;
        color: $yellow;
    }

    .name-date {
        display: block;
        font-size: 11px;
    }
}

.ratings-submit-form {
    position: relative;
    z-index: 1;

    textarea.form-control {
        height: 80px;
        font-size: 14px;
    }

    .stars {
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==") repeat-x 0 0;
        width: 150px;

        &:before,
        &:after {
            display: table;
            content: "";
        }

        &:after {
            clear: both;
        }

        input[type="radio"] {
            position: absolute;
            opacity: 0;

            &.star-5:checked~span {
                width: 100%;
            }

            &.star-4:checked~span {
                width: 80%;
            }

            &.star-3:checked~span {
                width: 60%;
            }

            &.star-2:checked~span {
                width: 40%;
            }

            &.star-1:checked~span {
                width: 20%;
            }
        }

        label {
            display: block;
            width: 30px;
            height: 30px;
            margin: 0 !important;
            padding: 0 !important;
            text-indent: -99999rem;
            float: left;
            position: relative;
            z-index: 10;
            background: transparent !important;
            cursor: pointer;

            &:hover~span {
                background-position: 0 -30px;
            }

            &.star-5:hover~span {
                width: 100% !important;
            }

            &.star-4:hover~span {
                width: 80% !important;
            }

            &.star-3:hover~span {
                width: 60% !important;
            }

            &.star-2:hover~span {
                width: 40% !important;
            }

            &.star-2:hover~span {
                width: 20% !important;
            }
        }

        span {
            display: block;
            width: 0;
            position: relative;
            top: 0;
            left: 0;
            height: 30px;
            background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==") repeat-x 0 -60px;
            transition: width 0.5s;
        }
    }
}

.discount-coupon-card {
    position: relative;
    z-index: 1;
    background-color: $purple !important;

    .coupon-text-wrap {
        h5 {
            border-right: 1px solid rgba(255, 255, 255, 0.75);
        }
    }
}

.offline-area-wrapper {
    position: relative;
    z-index: 1;
    width: 100%;
    height: calc(100vh - 108px);

    i {
        font-size: 3rem;
        display: block;
        margin-bottom: 1rem;
    }
}

.sales-offer-content {
    position: relative;
    z-index: 1;

    .sales-end {
        flex: 0 0 60%;
        max-width: 60%;
        width: 60%;
    }

    .sales-volume {
        flex: 0 0 40%;
        max-width: 40%;
        width: 40%;
    }

    .sales-volume {
        .progress {
            border-radius: 100px;
        }
    }
}

.sales-end-timer {
    li {
        line-height: 1;
        margin-left: 0.5rem;
        color: $purple;
        font-weight: 700;
        font-size: 13px;

        &:first-child {
            margin-left: 0;
        }

        span {
            margin-right: 1px;
            color: $purple;
        }
    }
}

.product-catagories {
    position: relative;
    z-index: 1;
    margin-bottom: 1rem;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .375rem .75rem;
        background-color: $white;
        border-radius: .5rem;
        color: $heading;
        font-size: 12px;
        font-weight: 700;

        img {
            max-width: 1.5rem;
            margin-right: 0.375rem;
        }

        &:hover,
        &:focus {
            color: $purple;
        }
    }
}

.single-product-slide,
.blog-details-post-thumbnail {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 380px;
    background-position: center center;
    background-size: cover;

    @media #{$breakpoint-xs-landscape} {
        height: 450px;
    }

    @media #{$breakpoint-md} {
        height: 500px;
    }

    @media #{$breakpoint-lg} {
        height: 520px;
    }

    @media #{$breakpoint-xl} {
        height: 600px;
    }
}

.product{
    &-header{
        display: flex;
        @media (max-width: 991px) {
            display: block;
          }
    }
    &-slider{
        max-width: 60%;
        margin-right: 10px;
        @media (max-width: 991px) {
            max-width: 100%;
            margin-right: 0;
          }
    }
}

.product-detail {
    p {
        margin: 0px !important;
    }
}