$fonts: "inherit", "Segoe UI",system-ui,"Apple Color Emoji","Segoe UI Emoji",sans-serif;

// Primary Color
$primary: #100DD1;

// Hover Color
$hover: #100DD1;

// White Color
$white: #ffffff;

// Secondary Color
$secondary: #546474;

// Dark Color
$dark: #090909;

// Heading Color
$heading: #020310;

// Text Color
$text: #747794;

// Text Gray Color
$text-gray: #c2d4f8;

// Border Color
$border: #ebebeb;

// Gray Background
$bg-gray: #fef8ff;

// Green Background
$green: #00b894;

// Yellow Background
$yellow: #ffaf00;

// Purple Background
$purple: #ea4c62;

// Dark Primary
$dark-primary: #0C153B;

// Dark Secondary
$dark-sec: #242644;

$custom-primary: #d63f08;
$custom-secondary: #d63f08;

$bg-mixin-custom-primary: #f76632;
$bg-mixin-custom-secondary: #f27345;
