.customer-support {
  .black {
    color: black;
  }
  
  .indent-30 {
    text-indent: 30px;
  }
  
  .contain {
    padding-top: 20px;
    padding-bottom: 20px;
    h5 {
      font-weight: 700;
      margin-bottom: 20px;
    }
    h6 {
      color: #1caf4d;
    }
    p {
      font-size: 15px;
      color: black;
    }
    ul {
      li {
        list-style: disc;
        color: black;
      }
    }
    &_row {
      display: flex;
      width: 100%;
      input {
          &:last-child{
              margin-left: 10px;
          }
      }
      @media (max-width: 767px) {
        display: block;
        input {
          &:last-child {
            margin-top: 15px;
            margin-left: 0;
          }
        }
      }
    }
    &_formContact {
      width: 100%;
    }
    &_map {
      width: 100%;
      margin-left: 15px;
      @media (max-width: 767px) {
        margin-left: 0;
        margin-top: 20px;
      }
    }
    &_textarea {
      height: 100px;
    }
    &_button {
      text-align: center;
      background: #1caf4d;
      color: #ffffff;
      padding: 12px 40px;
      border: none;
      display: block;
      text-transform: uppercase;
      border-radius: 4px;
      margin-top: 10px;
      &:disabled{
          opacity: 0.4;
      }
    }
  }
  
  .pb-20 {
    padding-bottom: 20px;
  }
  
  .pb-70 {
    padding-bottom: 70px;
  }
  
  .w-100 {
    width: 100%;
  }
  
  .t-center {
    text-align: center;
  }
  
  .ml-8 {
    margin-left: 8px;
  }
  
  .p-errors {
    color: red !important;
    text-align: left;
    margin-top: 5px;
  }
  
  .contact_phone {
    display: flex;
    li {
      &:last-child {
        margin-left: 40px;
      }
    }
  }
}