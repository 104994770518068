.suha-sidenav-wrapper {
    transition-duration: 500ms;
    position: fixed;
    width: 100%;
    z-index: 999999;
    top: 0;
    right: -600px;
    height: 100%;
    background-color: white;
    overflow-y: auto;
    width: 50%;
    max-width: 350px;
    min-width: 160px;
    .go-home-btn {
        transition-duration: 400ms;
        position: fixed;
        bottom: 30px;
        left: 170px;
        background-color: $green;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        color: $white;
        line-height: 42px;
        z-index: 10;
        cursor: pointer;
        visibility: hidden;
        opacity: 0;
        font-size: 1.25rem;

        &:hover,
        &:focus {
            background-color: $heading;
        }
    }
    a,a > * {
        color: black !important;

    }
    &.nav-active {
        right: 0;

        .go-home-btn {
            visibility: visible;
            opacity: 1;
        }
    }

    &.filter-nav {
        background: $bg-gray;
    }
}


.sidenav-black-overlay {
    transition-duration: 500ms;
    width: 100%;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(2, 3, 16, .3);
    z-index: 9999;
    opacity: 0;
    visibility: hidden;

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.sidenav-profile {
    position: relative;
    z-index: 1;
    @include bg-custom-gradient;

    .user-profile {
        position: relative;
        z-index: 1;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 3rem auto 1.5rem;

        &::before {
            position: absolute;
            width: 110px;
            height: 110px;
            top: -15px;
            left: -15px;
            content: "";
            z-index: -10;
            opacity: 0.2;
            border-radius: 50%;
        }

        img {
            border-radius: 50%;
        }

        .user-designation {
            position: absolute;
            z-index: 1;
            height: 46px;
            width: 46px;
            font-size: 12px;
            border: 3px solid $white;
            background-color: $primary;
            display: inline-block;
            border-radius: 50%;
            color: $white;
            line-height: 40px;
            text-align: center;
            font-weight: 700;
            top: 0;
            right: 0;
        }
    }

    .user-info {
        position: relative;
        z-index: 1;
        text-align: left;
        padding: 1rem 1rem 1rem 2rem;
        h6 {
            font-weight: 700;
            color: $white !important;
        }
        .available-balance {
            width: fit-content;
            color: $custom-primary !important;
            padding: 0px 5px;
            border-radius: 25px;
            background-color: $white;
            display: block;
            font-size: 10px;
            color: $white;
        }
    }
}

.sidenav-nav {
    margin: 0.5rem 1rem 3rem 1rem;
    position: relative;
    z-index: 1;

    li {
        a {
            display: flex;
            align-items: center;
            color: $white;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            // padding-right: 1rem;
            // padding-left: 1rem;
            font-size: 14px;
            font-weight: 700;
            border-radius: 0.375rem;

            i {
                transition-duration: 500ms;
                display: inline-block;
                margin-right: 0.5rem;
                font-size: 1rem;
                color: $white;
            }

            &:hover,
            &:focus {
                background-color: rgba(15, 15, 15, 0.1);
                font-weight: 700;
            }
        }

        h1 {
            &:hover,
            &:focus {
                background-color: rgba(15, 15, 15, 0.1);
                font-weight: 700;
            }
        }

        &:last-child {
            a {
                margin-bottom: 0;
            }
        }
    }
}

li.suha-dropdown-menu {
    position: relative;
    z-index: 1;

    ul {
        display: none;
        padding-left: 1rem;

        li {
            a {
                font-size: 13px;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
            }
        }
    }

    .dropdown-trigger-btn {
        height: 45px;
        line-height: 45px;
        color: $white;
        font-size: 12px;
        width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        text-align: right;
        padding-right: 1rem;
        border-radius: 0.375rem;
        cursor: pointer;

        i {
            transition-duration: 500ms;
        }

        &:hover,
        &:focus {
            background-color: rgba(15, 15, 15, 0.1);
        }

        &.active {
            background-color: rgba(15, 15, 15, 0.1);

            i {
                transform: rotate(-180deg);
            }
        }
    }
}

.sidebar-link{
    cursor: pointer;
}