/* FAQ Search CSS */

.faq-search-form {
    position: relative;
    z-index: 1;
    margin-left: 1rem;
    margin-right: 1rem;

    input {
        border-radius: 60px;
        padding-left: 40px;
        font-weight: 700;
        border-width: 2px;
        height: auto !important;

        &:focus {
            border-color: $primary;
        }
    }

    button {
        position: absolute;
        width: 50px;
        height: 37px;
        border: 0;
        background-color: transparent;
        text-align: center;
        top: 0;
        left: 0;
        z-index: 10;
        outline: none !important;
    }
}

.support-wrapper {
    .faq-heading {
        background: $primary;
        background: -webkit-linear-gradient(to right, $primary, $green);
        background: linear-gradient(to right, $primary, $green);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 800;
    }
}